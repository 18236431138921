import PropTypes from 'prop-types';
import React from 'react';
import startCase from 'lodash/startCase';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { windowLocationReload } from '@eva/emf/app/shared/functions';
import { leftMenuModes, routesNames } from 'shared/constants';
import {
  getQueryVariables,
  getParsedUser,
  prepareSelector,
  temporaryUser,
  clearQueryVariables,
  redirectTo,
  getNextPortalPageUrl,
  redirectToExternalUrl,
} from 'shared/functions';

import ModalAlert from 'containers/sharedModals/ModalAlert';
import MessengerMobileHeader from 'containers/MessengerMobileHeader';
import ModalTermsAndConditions from 'containers/ModalTermsAndConditions';
import { markPipelinesRead } from 'containers/MessengerCandidate/actions';

import MenuChat from './MenuChat';
import MenuHome from './MenuHome';
import MenuMyProfile from './MenuMyProfile';
import MenuMyJobs from './MenuMyJobs';
import MenuRecommended from './MenuRecommended';
import { mainMenuKeys } from './constants';
import { buildPathFromMenuKey, getMenuKey } from './functions';
import SignInForm from './SignInForm';

const EmptyDiv = () => <div />;

const menuItems = {
  [mainMenuKeys.home]: {
    icon: 'lnr lnr-home',
    component: MenuHome,
    title: 'Home page',
    badge: 0,
  },
  [mainMenuKeys.myProfile]: {
    icon: 'lnr lnr-user',
    component: MenuMyProfile,
    title: 'View and edit your Profile',
    badge: 0,
  },
  [mainMenuKeys.chat]: {
    icon: 'll-icon ll-chat fa-2x',
    component: MenuChat,
    title: 'View your EVAbot Conversation',
    badge: 0,
  },
  [mainMenuKeys.myJobs]: {
    icon: 'lnr lnr-briefcase',
    viewPermission: 'frontend-myJobs-view',
    component: MenuMyJobs,
    title: 'Opportunity you are interested in',
    badge: 0,
  },
  [mainMenuKeys.recommended]: {
    icon: 'll-icon ll-match',
    component: MenuRecommended,
    title: 'Recommended',
    badge: 0,
  },
};

class MessengerLeftMenu extends React.Component<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    badges: {},
    initiator: mainMenuKeys.chat,
    openOnSwitch: 0,
    readMessagesIds: [],
    filteredMenuItemsKeys: [],
  };
  termsAndConditionsModal: any;
  static contextTypes: {
    isAllowedOperation: PropTypes.Validator<(...args: any[]) => any>;
    settings: PropTypes.Validator<object>;
    router: PropTypes.Validator<object>;
  };
  modalAlert: any;

  UNSAFE_componentWillMount() {
    const { settings } = this.context;
    const { chats, messages } = this.props;
    const { isAllowedOperation } = this.context;

    const query = getQueryVariables();
    const { accountTypes } = getParsedUser();
    const { gettingStartedPage } = settings?.features?.ui?.mainMenu;

    // @ts-expect-error
    this.query = query;
    this.defaultMenuItem();
    this.updateMessagesBadge(messages);
    this.updateLeftPane();
    if (this.props.user.userId && !Object.keys(chats).length) {
      this.switchMenuItem(mainMenuKeys.chat, true);
    }
    // @ts-expect-error
    if (!this.props.mobileMode && !query.job) {
      const menuKey = getMenuKey();
      this.switchTopMenu(menuKey, true);
    }
    if (this.props.mobileMode && this.props.mobileActiveMenuKey) {
      this.switchTopMenu(this.props.mobileActiveMenuKey, true);
    }

    this.setState({});

    const filteredMenuItemsKeys = Object.keys(menuItems).filter((menuItemsKey) => {
      const permission = menuItems[menuItemsKey].viewPermission;
      if (permission && !isAllowedOperation(permission)) {
        return false;
      }
      return menuItemsKey !== mainMenuKeys.home || gettingStartedPage;
    });

    this.setState({
      filteredMenuItemsKeys,
      showAccountSelector: Array.isArray(accountTypes) && accountTypes.length > 1,
    });
  }

  componentDidMount() {
    const { pipelinesLoaded } = this.props;

    const query = getQueryVariables();

    // @ts-expect-error
    if (query.job && pipelinesLoaded) {
      this.openQueryJob();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { leftPane, user, messages, pipelines, newCandidate, dispatchLoadPipelines, pipelinesLoaded } = nextProps;

    const query: any = getQueryVariables();

    if (newCandidate || this.props.user.userId !== user.userId) {
      this.defaultMenuItem(nextProps);
    }

    if (!this.props.user.userId && user.userId) {
      this.switchToChat();
    }

    if (this.props.user !== user && user.userId) {
      dispatchLoadPipelines();
    }

    if (query.job && !this.props.pipelinesLoaded && pipelinesLoaded) {
      this.openQueryJob(nextProps);
    }

    if (this.props.messages !== messages) {
      this.updateMessagesBadge(messages);
    }

    if (this.props.leftPane !== leftPane) {
      this.setState({ leftPane });
    } else if (query.rp) {
      this.updateLeftPane(nextProps);
    }

    if (this.props.pipelines !== pipelines) {
      this.setState({
        badges: {
          ...this.state.badges,
          myJobs: pipelines.filter((pipeline) => !pipeline.readOn).length,
        },
      });
    }
  }

  updateLeftPane(props = this.props) {
    const { leftPane } = props;

    const query = getQueryVariables();

    this.setState({
      // @ts-expect-error
      leftPane: query.rp ? leftMenuModes.restorePassword : leftPane || leftMenuModes.menu,
      activeMenuKey: leftPane === leftMenuModes.signIn ? '' : mainMenuKeys.chat,
    });
  }

  openQueryJob(props = this.props) {
    const { pipelines } = props;

    const query: any = getQueryVariables();

    const newQuery = { ...query };
    if (!query.job) {
      return;
    }
    const pipeline = pipelines.find((item) => item.job.code === query.job);
    if (pipeline) {
      this.setState(
        {
          openOnSwitch: pipeline.pipelineId,
        },
        () => {
          this.switchMenuItem(mainMenuKeys.myJobs);
        },
      );
    } else {
      this.modalAlert.open(
        translate('Requested job with id {{ jobId }} not found!', {
          jobId: query.job,
        }),
      );
    }
    delete newQuery.job;
    redirectTo({
      pathname: window.location.pathname,
      query: newQuery,
    });
  }

  toggleMobileMenu = (mode) => {
    this.setState({
      activeMenuKey: mode === leftMenuModes.signIn ? '' : mainMenuKeys.chat,
      leftPane: mode,
    });
  };

  updateMessagesBadge(messages) {
    const { activeMenuKey } = this.state as any;

    const readMessagesIds =
      activeMenuKey === mainMenuKeys.chat ? messages.map((message) => message.messageId) : this.state.readMessagesIds;
    this.setState({
      badges: {
        ...this.state.badges,
        [mainMenuKeys.chat]: messages.filter(
          (message) => !message.readOn && !readMessagesIds.includes(message.messageId),
        ).length,
      },
      readMessagesIds,
    });
  }

  defaultMenuItem(props = this.props) {
    const { newCandidate, user } = props;

    this.setState({
      activeMenuKey: !newCandidate || user.userId ? mainMenuKeys.chat : '',
    });
  }

  switchTopMenu = (menuKey, initialCall) => {
    if (!mainMenuKeys[menuKey]) {
      return this.switchTopMenu(mainMenuKeys.chat, initialCall);
    }
    if (menuKey === mainMenuKeys.chat) {
      this.switchToChat();
    } else {
      this.switchMenuItem(menuKey, initialCall);
    }
  };

  resetLocalStorageAndReload = () => {
    Object.keys(localStorage).forEach((localStorageKey) => delete localStorage[localStorageKey]);
    windowLocationReload();
  };

  switchMenuItem = (menuItemKey, initialCall?) => {
    const { conversationOver, user, newCandidate, setCentralContent, toggleLeft } = this.props;

    const defaultRequested = menuItemKey === mainMenuKeys.chat;
    if (!defaultRequested && (newCandidate || !user.userId)) {
      window.sendGAEvent('CandidateMessengerMenuClickFail', 'click', 'CMNegativeFlow');
      return (
        this.modalAlert &&
        this.modalAlert.open(
          translate('You need to sign up/in to unlock all of our features including being able to apply for jobs'),
        )
      );
    }

    if (conversationOver) {
      return this.modalAlert.open(translate('For support, please contact support&#64;eva.ai'));
    }

    this.setState(
      {
        activeMenuKey: menuItemKey,
        initiator: menuItemKey,
      },
      () => {
        redirectTo({
          pathname: buildPathFromMenuKey(menuItemKey),
          query: getQueryVariables(),
        });
        if (!initialCall) {
          clearQueryVariables();
        }
      },
    );

    if (defaultRequested) {
      setCentralContent();
      toggleLeft();
    }
  };

  switchToChat = () => {
    const { setCentralContent, toggleLeft, messages } = this.props;

    this.setState(
      {
        activeMenuKey: mainMenuKeys.chat,
        readMessagesIds: messages.map((message) => message.messageId),
      },
      () => {
        this.updateMessagesBadge(this.props.messages);

        redirectTo({
          pathname: buildPathFromMenuKey(mainMenuKeys.chat),
          query: getQueryVariables(),
        });

        clearQueryVariables();
      },
    );
    setCentralContent();
    toggleLeft();
  };

  switchPortal = () => {
    const { dispatchSignOut, user } = this.props;
    try {
      const portalPage = getNextPortalPageUrl(user.accountTypes);
      portalPage === `/${routesNames.candidate}` ? redirectTo(portalPage) : redirectToExternalUrl(portalPage);
    } catch (e) {
      dispatchSignOut();
    }
  };

  render() {
    const { settings } = this.context;
    const {
      user,
      userDetails,
      editOptions,
      emitSendMessage,
      pipelines,
      loadingPipelines,
      mobileMode,
      newCandidate,
      setCentralContent,
      centralContent,
      dispatchMarkPipelinesRead,
      dispatchSignOut,
      userProfile,
    } = this.props;
    const { activeMenuKey, badges, leftPane, initiator, showAccountSelector, openOnSwitch, filteredMenuItemsKeys } =
      this.state as any;

    const menuComponent = activeMenuKey ? menuItems[activeMenuKey].component : EmptyDiv;

    if (mobileMode && centralContent) {
      return null;
    }

    const renderMenuItem = (key) => {
      const menuItem = menuItems[key];
      const active = activeMenuKey === key || (!activeMenuKey && key === mainMenuKeys.chat);
      return (
        // @ts-expect-error
        <li key={key} className={active ? 'active' : ''} onClick={() => this.switchTopMenu(key)}>
          <a className={mobileMode && key === initiator ? 'active-mobile' : ''} title={translate(menuItem.title)}>
            <i className={`${menuItem.icon}`}>{!!badges[key] && <span className="badge">{badges[key]}</span>}</i>
            <span>{translate(startCase(key))}</span>
          </a>
        </li>
      );
    };

    const defaultMenuActive = activeMenuKey === mainMenuKeys.chat;
    const showAuthSignIn =
      ((!mobileMode && leftPane !== leftMenuModes.restorePassword) || leftPane === leftMenuModes.signIn) &&
      (newCandidate || !user.userId);
    const closeButton = mobileMode && initiator && (
      <span
        className="ll-icon ll-close pull-left"
        style={{
          margin: '-5px 0 0 -20px',
          color: '#29638F',
        }}
        onClick={() => this.switchMenuItem(initiator)}
      ></span>
    );

    return (
      <div className={`left ${mobileMode ? 'mobile-left' : ''}`}>
        <ModalTermsAndConditions
          key="termsAndConditionsModal"
          ref={(ref) => (this.termsAndConditionsModal = this.termsAndConditionsModal || ref)}
          size="large"
        />

        <ModalAlert key="modalAlert" ref={(ref) => (this.modalAlert = this.modalAlert || ref)} />

        {mobileMode && (!defaultMenuActive || leftPane === leftMenuModes.signIn) && (
          <MessengerMobileHeader
            user={user}
            userDetails={userDetails}
            userProfile={userProfile}
            title={startCase(activeMenuKey)}
            toggleLeft={this.toggleMobileMenu}
            // @ts-expect-error
            newCandidate={newCandidate}
            dispatchSignOut={dispatchSignOut}
          />
        )}

        {(!mobileMode || (defaultMenuActive && leftPane === leftMenuModes.menu)) && (
          <div key="menu-items" className="left-top">
            {closeButton}
            <ul className="nav blue-nav nav-justified text-center">
              {filteredMenuItemsKeys.map((key) => renderMenuItem(key))}
            </ul>
          </div>
        )}

        <div
          key="auth"
          id="messenger-left-menu"
          className="left-center"
          // @ts-expect-error
          ref={(ref) => (this.leftCenterContainer = ref)}
        >
          {showAuthSignIn && <SignInForm user={user} userDetails={userDetails} />}
          {!newCandidate &&
            React.createElement(menuComponent, {
              editOptions,
              emitSendMessage,
              pipelines,
              openOnSwitch,
              loadingPipelines,
              setCentralContent,
              switchToChat: this.switchToChat,
              switchMenuItem: this.switchMenuItem,
              mobileMode,
              parentId: 'messenger-left-menu',
              dispatchMarkPipelinesRead,
            })}

          {mobileMode && leftPane === leftMenuModes.menu && defaultMenuActive && (
            <div className="candidate-info-menu">
              <div
                className="col-xs-6"
                onClick={() => {
                  window.open('//www.eva.ai');
                  this.switchToChat();
                }}
              >
                {translate('About EVA.ai')}
              </div>
              <div className="col-xs-6" onClick={() => this.termsAndConditionsModal.open()}>
                {translate('Legal terms')}
              </div>
              {showAccountSelector && (
                <div className="col-xs-6" onClick={this.switchPortal}>
                  <span>{translate('Select portal')}</span>
                </div>
              )}
              {user.userId && !temporaryUser(userDetails) && (
                <div
                  className="col-xs-6 text-danger"
                  onClick={() => {
                    dispatchSignOut();
                    this.setState({ leftPane: leftMenuModes.signIn });
                  }}
                >
                  {translate('Sign out')}
                </div>
              )}

              <div className="col-xs-12 text-center">
                <hr className="dotted" />
                <img className="logo" style={{ maxHeight: '75px' }} src={settings.logos.mainLogo} alt="" />
              </div>
            </div>
          )}
        </div>

        {!mobileMode && (
          <div key="main-logo" className="left-bottom">
            <div className="logo-container">
              <img className="logo" src={settings.logos.mainLogo} alt="" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

MessengerLeftMenu.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

// @ts-expect-error
MessengerLeftMenu.propTypes = {
  user: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  chats: PropTypes.object.isRequired,
  editOptions: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  forceEmail: PropTypes.string.isRequired,
  mobileMode: PropTypes.bool.isRequired,
  toggleLeft: PropTypes.func.isRequired,
  leftPane: PropTypes.number,
  newCandidate: PropTypes.bool.isRequired,
  conversationOver: PropTypes.bool.isRequired,
  pipelines: PropTypes.array.isRequired,
  loadingPipelines: PropTypes.bool.isRequired,
  pipelinesLoaded: PropTypes.bool.isRequired,
  emitSendMessage: PropTypes.func.isRequired,
  setCentralContent: PropTypes.func.isRequired,
  centralContent: PropTypes.bool.isRequired,
  resetPasswordError: PropTypes.string.isRequired,
  dispatchSignOut: PropTypes.func.isRequired,
  dispatchLoadPipelines: PropTypes.func.isRequired,
  dispatchMarkPipelinesRead: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  mobileActiveMenuKey: PropTypes.string,
};

const prepareMessengerSelector = (value) => prepareSelector('candidate', value);
const prepareGlobalSelector = (value) => prepareSelector('global', value);
const mapStateToProps = createStructuredSelector({
  userProfile: prepareGlobalSelector('userProfile'),
  resetPasswordError: prepareGlobalSelector('resetPasswordError'),
  chats: prepareMessengerSelector('chats'),
  editOptions: prepareMessengerSelector('editOptions'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchMarkPipelinesRead: (pipelinesIds) => dispatch(markPipelinesRead(pipelinesIds)),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(MessengerLeftMenu);
